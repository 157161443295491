<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <Event />
  </v-container>
</template>

<script>
	import Event from '@/components/Event/Event'
	export default {
		components:{
			Event,
		},
    data:()=>({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("route.PageMainHome"),
        to:{name:'PageMainHome'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:this.$t("route.PageMainEvent"),
        to:{name:'PageMainEvent'},
        exact:true,
      })
      //EOC
    },
	};
</script>